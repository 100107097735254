import axios from '@axios'
import MockAdapter from 'axios-mock-adapter'
export default mock
const mock = new MockAdapter(axios)

/* eslint-disable global-require */
const data = {
	faqData: {
		iptu: {
			icon: 'DollarSignIcon',
			title: 'IPTU',
			qandA: [
				{
					question: 'Preciso pagar o IPTU?',
					ans: `
                    Sim, a partir da assinatura do Contrato de Compra e Venda, você precisa ficar atento aos pagamentos de IPTU (e outros débitos municipais) incidentes sobre o lote.
                    `,
				},
				{
					question: 'Como pagar o meu IPTU?',
					ans: `
                    <p>
                    Você pode ir até a Prefeitura com o Contrato de Compra e Venda em mãos ou acessar o site com o BIC/Inscrição (Cadastro do Imóvel) do seu lote:
                    </p>
              
                    <p>Links de acesso:</p>
                    <ul>
                            <li>
                                <a href="http://18.229.15.254:8080/cidadao/servlet/br.com.cetil.ar.jvlle.hatendimento" target="_blank">
                                    Prefeitura de Naviraí
                                </a>
                            </li>
                            <li>
                                <a href="https://e-gov.betha.com.br/cdweb/03114-302/main.faces" target="_blank">
                                    Prefeitura de Itaquiraí
                                </a>
                            </li>
                            <li>
                                <a href="http://egov2.dourados.ms.gov.br/webAtendimento/" target="_blank">
                                    Prefeitura de Dourados
                                </a>
                            </li>
                            <li>
                                <a href="https://e-gov.betha.com.br/cdweb/03114-302/main.faces" target="_blank">
                                    Prefeitura de Maracaju
                                </a>
                            </li>
                            <li>
                                <a href="https://www.gp.srv.br/tributario/guarantadonorte/portal_serv_capa?6" target="_blank">
                                    Prefeitura de Guarantã do Norte
                                </a>
                            </li>
                            <li>
                                <a href="http://app.saogabriel.ms.gov.br:8085/servicosweb/home.jsf" target="_blank">    
                                    Prefeitura de São Gabriel do Oeste
                                </a>
                            </li>
                            <li>
                                <a href="https://iptu.ivinhema.ms.gov.br:8443/servicosweb/home.jsf" target="_blank">
                                    Prefeitura de Ivinhema
                                </a>
                            </li>                             
                        </ul>
                        *Caso não tenha o BIC ou Inscrição (Cadastro do Imóvel), a solicitação pode ser feita 
                                <a href="https://api.whatsapp.com/send/?phone=556734205600" target="_blank">
                                    Clicando Aqui
                                </a>                               
                    `,
				},
				{
					question: 'Posso parcelar o IPTU?',
					ans: `Sim, além da opção de pagamento à vista com desconto, cada município define suas datas, regras e opções de parcelamento. Assim que o IPTU é lançado, as opções, tanto à vista, quanto parcelado, ficarão disponíveis no site da Prefeitura do município do seu lote.
                    `,
				},
				{
					question: 'E se eu atrasar ou não pagar meu IPTU?   ',
					ans: `A falta de pagamento do IPTU (e outros débitos municipais) incidirá em juros, multas e correções, podendo também, seu lote sofrer Execução Fiscal assim que o débito se torna dívida ativa e até mesmo ser leiloado para quitar a dívida. Evite atrasos e riscos maiores.
                    `,
				},
			],
		},
		demarcacao: {
			icon: 'FlagIcon',
			title: 'Demarcação do lote',
			qandA: [
				{
					question: 'O que é a demarcação de lote?',
					ans: `
                    A demarcação de Lote é quando você cliente vai construir e solicita a São Bento que coloque marcos nos quatro cantos do seu lote para que sua equipe de obra consiga identificar as divisas exatas do imóvel.                    
                    `,
				},
				{
					question: 'Como peço para demarcar o meu lote?',
					ans: `
                        Agora você solicita a demarcação de seu lote de forma simples e rápida pelo Portal do cliente. 
                        Para solicitar sua demarcação é só seguir os passo a passo abaixo:
                    
                        <ol>
                            <li>
                                Após o acesso ao Portal do Cliente, clique na aba de <b>SOLICITAÇÕES</b>.
                            </li>

                            <li>
                                Nessa etapa você visualizará a opção <a href="/programa-rocada"><b>DEMARCAÇÃO DE LOTE</b></a>, clique nela e depois em <b>QUERO SOLICITAR</b>.

                                O sistema rapidamente irá analisar a situação de seu lote, se estiver dentro dos parâmetros necessários, você terá a opção de clicar em <b>CONTINUAR</b>, leia atentamente os termos e condições, em seguida, clique em <b>DECLARO QUE LI E ESTOU DE ACORDO COM AS NORMAS CITADAS NESSE DOCUMENTO</b>.
                            </li>

                            <li>
                            E pronto, agora é só acompanhar sua solicitação em <a href="/minhas-solicitacoes"><b>MINHAS SOLICITAÇÕES</b></a>, assim você poderá visualizar as atualizações de sua demarcação até que ela seja realizada! 
                            </li>
                        </ol>    

                        <p>Segue um vídeo tutorial de Como solicitar a demarcação de lote pelo Portal do Cliente:</p>

                        <br/>
                        <br/>
                        <iframe width="100%" class="d-md-none mx-auto" src="https://www.youtube.com/embed/kF39cAmYeT8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    
    
                        <iframe width="560" height="315" class="d-none d-md-block mx-auto" src="https://www.youtube.com/embed/kF39cAmYeT8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                            

                    `,
				},
				{
					question: 'Existe algum custo para demarcar meu lote?',
					ans: `
                    A demarcação é um trabalho sério, caso ela saia errado você pode invadir o lote do seu vizinho sem querer, por isso disponibilizamos nossa equipe de topografia de alta precisão sem custo algum para nossos clientes. No entanto, caso o lote seja demarcado e você solicite uma segunda demarcação, por algum motivo, ela terá um custo de R$500,00. Por isso recomendamos que demarque o seu lote se realmente for construir.
          `,
				},
			],
		},
		escritura: {
			icon: 'PenToolIcon',
			title: 'Escritura',
			qandA: [
				{
					question: 'Quando posso escriturar meu lote?',
					ans: `
                    Após a quitação do contrato, você poderá emitir a Autorização de Escritura na aba
                    <a href="/documentos">DOCUMENTOS</a> aqui do portal do cliente.
                    processo de escritura. Em seguida, basta procurar o Cartório de Registros de sua preferência para iniciar o
                    <br/>
                    <br/>
                    Segue um vídeo explicativo sobre o que é a escritura do imóvel e qual sua importância.
                    <br/>
                    <br/>
                    <iframe width="100%" class="d-md-none mx-auto" src="https://www.youtube.com/embed/QBdSZEb92g8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


                    <iframe width="560" height="315" class="d-none d-md-block mx-auto" src="https://www.youtube.com/embed/QBdSZEb92g8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                    
                    `,
				},

				{
					question: 'Quais documentos o cartório solicita para Escriturar?',
					ans: `
                        Para esse processo, será necessária a apresentação de uma série de documentos
                        pessoais e do bem ou imóvel, conforme segue abaixo:
                        <ul>
                            <li>RG e CPF (Titular e Cônjuge);</li>
                            <li>Certidão de Nascimento e Casamento (atualizada 90 dias);</li>
                            <li>Pacto antenupcial registrado, (se houver);</li>
                            <li>Certidão de óbito (deverá ser apresentada se o comprador for viúvo);</li>
                            <li>Qualificação e endereço completos dos compradores: informar endereço, profissão.</li>
                            <li>Matrícula ou transcrição atualizada - Registro de Imóveis expedidas pelo Registro de</li>
                            <li>Imóveis (validade de 30 dias a partir da data de expedição);</li>
                            <li>Certidão Negativa de Débitos Condominiais (para imóveis em condomínio);</li>
                            <li>Carnê do IPTU do ano vigente e ou Certidão Negativa de IPTU;</li>
                            <li>Autorização de Escritura (emitida no Portal do Cliente);</li>                            
                        </ul>                  
                    `,
				},

				{
					question: 'Para escriturar, eu preciso pagar o ITBI?',
					ans: `
                        Sim, assim que manifestar interesse em escriturar, o Cartório de Registros solicitará o
                        pagamento do ITBI (Imposto de Transmissão de Bens e Imóveis) para dar sequência à sua
                        solicitação.                  
                    `,
				},

				{
					question: 'O que é preciso para gerar a Guia de ITBI?',
					ans: `
                    Para a emissão da guia, você deverá apresentar na Prefeitura os seguintes documentos:
                    <ul>
                        <li>O Contrato de Compra e Venda;</li>
                        <li>A Matrícula do Lote (emitida pelo Cartório);</li>
                        <li>A Autorização de Escritura (emitida pelo Portal do Cliente);</li>
                    </ul>
                    Após a entrega dos documentos, a Prefeitura irá protocolar o pedido e agendar uma data para entrega da Guia de ITBI.
                    `,
				},

				{
					question: 'Preciso fazer mais alguma coisa depois da Escritura?',
					ans: `
                        Sim, pois embora a escritura pública garanta à você os direitos sobre o bem, será apenas
                        após o Registro da Escritura que a propriedade é transferida definitivamente.
                        Para isso é só levar a escritura no Cartório de Registro de Imóveis da cidade onde está o
                        lote que você comprou e informar que precisa realizar o Registro da Escritura.
                    `,
				},

				{
					question: 'Eu tenho algum prazo para escriturar meu lote após a quitação?',
					ans: `
                        Sim, após a quitação você terá um prazo de 60 dias para realizar a Escritura Pública.
                    `,
				},

				{
					question: 'O prazo de 60 dias venceu, terei algum problema?',
					ans: `
                        Com o vencimento do prazo, você se encontrará irregular perante a São Bento. Sendo
                        assim, caso não inicie o quanto antes a Escritura, você poderá ser Notificado
                        Extrajudicialmente ou Judicialmente pela Obrigação de Fazer, conforme previsto no artigo
                        108 do Código Civil vigente.
                    `,
				},
			],
		},
		imposto: {
			icon: 'DollarSignIcon',
			title: 'Imposto de Renda',
			qandA: [
				{
					question: 'O que é o Imposto de Renda (IR)?',
					ans: `
                    O Imposto de Renda é um imposto Federal, no qual é cobrado tanto de Pessoa Física (PF) quanto de Jurídica (PJ). Segue um vídeo explicativo sobre o tema: <a target="_blank" href="https://www.youtube.com/watch?v=cSAr2RCg_UM&t">
                    <br/>
                    <br/>
					
					<iframe 
						width="560"
						height="315"
						class="d-none d-md-block mx-auto"
						src="https://www.youtube.com/embed/cSAr2RCg_UM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
					</iframe>

					<iframe 
						width="100%"
						class="d-md-none mx-auto"
						src="https://www.youtube.com/embed/cSAr2RCg_UM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
					</iframe>					
                    `,
				},
				{
					question: 'Como declarar o meu imóvel no Imposto de Renda?',
					ans: `
                        <p>Se você estiver enquadrado nas regras para declaração do Imposto de Renda e adquiriu um imóvel no último ano, onde realizou o pagamento à vista ou parcelado, em ambos os casos, o valor deve ser informado na ficha de bens e direitos.</p>
                        <p>
                        Sendo assim, você precisará dos dados do vendedor e das informações do seu lote, tais como: endereço do lote, número de quadra e número de lote. Além dessas informações, você precisará informar na descrição do imóvel se é uma casa, lote ou apartamento. E também, qual foi o processo de compra escolhido, à vista ou parcelado e se houve entrada, deve informar o valor, assim como a quantidade e os valores de cada parcela paga. Todavia, fique atento, a declaração deverá ser respectiva ao ano anterior.</p>
                        <br/>
                        <p>Achou difícil? Fique tranquilo!</p>
                        <br/>
                        <p>
                        A São Bento já tem todas as informações referentes ao seu lote, prontas para declarar, basta acessar <b><a href="/Documentos">Documentos</a></b>, em seguida clicar em <b>Informe de Imposto de Renda</b>, selecionar o ano que precisa declarar e clicar em confirmar. E pronto, o seu Informe de Imposto de Renda será gerado automaticamente.
                        </p>
                        <br/>
                        <p>Segue um vídeo tutorial de apenas 14 segundos de Como emitir seu Informe do Imposto de Renda pelo Portal do Cliente:</p>
                        <br/>
                        <br/>
                        <iframe width="100%" class="d-md-none mx-auto" src="https://www.youtube.com/embed/1x1RQHzwMQE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    
    
                        <iframe width="560" height="315" class="d-none d-md-block mx-auto" src="https://www.youtube.com/embed/1x1RQHzwMQE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                    
    

                    `,
				},
				{
					question: 'Sou obrigado a declarar o Imposto de Renda (IR)?',
					ans: `
                    <p>
                        Para pessoa física, a obrigatoriedade da declaração do Imposto de Renda ocorre a partir dos valores de salários tabelados pela Receita Federal, assim como a necessidade de declaração de investimentos e custos com imóvel, saúde, educação, entre outros. 
                    </p>
                    <p>
                        Por exemplo, para o ano de 2023, seguem os valores estipulados pela Receita Federal:
                    </p>
                        <ul>
                            <li>
                                Obteve rendimentos tributáveis acima de R$ 28.559,70 no ano (salário, aposentadoria ou aluguéis, entre outros);
                            </li>
                            <li>
                                Recebeu acima de R$ 40 mil isentos, não tributáveis ou tributados na fonte no ano (como indenização trabalhista ou rendimento de poupança), entre outros;
                            </li>
                            <li>
                                Obteve em qualquer mês de 2022, ganho de capital na alienação de bens ou direitos, sujeito à incidência do imposto, ou realizou operações em bolsas de valores, de mercadorias, de futuros e assemelhadas cuja soma foi superior a R$ 40.000,00 (quarenta mil reais), ou com apuração de ganhos líquidos sujeitas à incidência do imposto;
                            </li>
                            <li>
                                Teve isenção de imposto sobre o ganho de capital na venda de imóveis residenciais, seguido de aquisição de outro imóvel residencial no prazo de 180 dias;
                            </li>
                            <li>
                                Teve até 31 de dezembro de 2022, a posse ou a propriedade de bens ou direitos, inclusive terra nua, de valor total superior a R$ 300 mil;
                            </li>
                            <li>
                                Teve em 2022, receita bruta em valor superior a R$ 142.798,50 em atividade rural;
                            </li>
                            <li>
                                Passou para a condição de residente no Brasil em qualquer mês e se encontrava nessa condição até 31 de dezembro de 2022.
                            </li>
                        </ul>     
                    
                    <p>
                        Para pessoa jurídica, a obrigatoriedade da declaração do Imposto de Renda (ECF) ocorre a partir dos requisitos abaixo:
                    </p>

                    <ul>
                        <li>
                            Todas as empresas que tiveram receita bruta superior a R$ 4.800.000,00 em 2022;
                        </li>
                        <li>
                        As empresas que, independentemente do valor da receita bruta, estejam enquadradas em algum dos seguintes regimes tributários: Lucro Real, Lucro Presumido ou Arbitrado;                        </li>
                        <li>
                        As empresas que possuam bens ou direitos no exterior, ou que realizaram operações comerciais com pessoas físicas ou jurídicas no exterior, com valor total igual ou superior a US$ 100.000,00;
                        </li>
                        <li>
                        As empresas que realizaram operações de alienação de bens ou direitos em que tenha havido ganho de capital sujeito à incidência do imposto, ainda que a empresa esteja inativa;
                        </li>
                        <li>
                        As empresas que, até 31 de dezembro de 2022, tenham realizado operações em bolsas de valores, de mercadorias, de futuros e assemelhadas;
                        </li>
                        <li>
                        As empresas que tiverem prejuízos fiscais a compensar de períodos anteriores ou que realizaram pagamentos de juros sobre o capital próprio aos sócios ou acionistas.
                        </li>
                    </ul>            
                    
                    <p>
                        Lembrando que, para Pessoa Jurídica optante pelo Simples Nacional, o Imposto de Renda é apurado e declarado mensalmente pelo PGDAS (Programa Gerador do Documento de Arrecadação do Simples Nacional), para enquadramento nesse regime tributário, o limite de faturamento é de até R$ 4.800.000,00 por ano.
                    </p>
                    `,
				},
				{
					question: 'O que acontece se eu não entregar a declaração do Imposto de Renda?',
					ans: `
                        <p>
                            Se você não entregar a declaração do Imposto de Renda dentro do prazo estabelecido pela Receita Federal, estará sujeito a uma série de sanções e multas, que podem se acumular com o passar do tempo. As principais consequências incluem:
                        </p>
                    
                        <ul>
                            <li>
                            Multa por atraso na entrega: a Receita Federal cobra uma multa de 1% do imposto devido por mês de atraso na entrega, com valor mínimo de R$ 165,74 e máximo de 20% do imposto devido. Se você não tem imposto a pagar, a multa será de R$ 165,74.
                            </li>
                            <li>
                            Impedimento de obter empréstimos: a falta de entrega da declaração pode impedir que você obtenha empréstimos, financiamentos e outros serviços bancários, já que muitas instituições financeiras exigem a declaração do Imposto de Renda como comprovante de renda.
                            </li>
                            <li>
                            Impedimento de emissão de certidões: a falta de entrega da declaração também pode impedir a emissão de certidões negativas de débitos, que são exigidas em diversas situações, como na compra e venda de imóveis, em processos de licitação e em renovações de alvarás.
                            </li>
                            <li>
                            Cobrança judicial: a Receita Federal pode ingressar com uma ação judicial para cobrar o imposto devido e as multas, o que pode resultar em bloqueios de contas bancárias e outros bens.
                            </li>
                        </ul> 

                        <p>
                            Portanto, é importante ficar atento ao prazo de entrega da declaração do Imposto de Renda e providenciar sua entrega dentro do período estipulado para evitar problemas com o fisco.
                        </p>                        
                    `,
				},
			],
		},
		cessaoDireitos: {
			icon: 'BriefcaseIcon',
			title: 'Cessão de Direitos',
			qandA: [
				{
					question: 'O que é a Cessão de Direitos (transferência de titularidade)?',
					ans: `
                    <p class="c10">
                    <span class="c0"
                        >A Cess&atilde;o de Direitos, mais conhecida como transfer&ecirc;ncia de titularidade,
                        &eacute; um procedimento realizado pela S&atilde;o Bento, quando algum cliente nosso
                        vende seu lote - que ainda n&atilde;o est&aacute; quitado - para outra pessoa.</span
                    >
                    </p>
                    <p class="c10 c15"><span class="c0"></span></p>
                    <p class="c10">
                        <span>Segue um v&iacute;deo explicativo sobre o tema: </span
                        ><span class="c35 c20"
                            ><a
                                class="c41"
                                href="https://www.youtube.com/watch?v=e2HIEVsEceo"
                                >O que &eacute; a Cess&atilde;o de Direitos e como ela funciona?</a
                            ></span
                        >
                    </p>
					<iframe 
						width="560"
						height="315"
						class="d-none d-md-block mx-auto"
						src="https://www.youtube.com/embed/e2HIEVsEceo?si=KOUHFivslObg65mg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
					</iframe>

					<iframe 
						width="100%"
						class="d-md-none mx-auto"
						src="https://www.youtube.com/embed/e2HIEVsEceo?si=KOUHFivslObg65mg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
					</iframe>	                                       
                    `,
				},
				{
					question: 'Como faço para solicitar a Cessão de Direitos do meu contrato?',
					ans: `
                    <p class="c10">
                    <span
                        >Solicitar uma Cess&atilde;o de Direitos &eacute; muito simples, basta entrar em
                        contato com a S&atilde;o Bento e solicitar a um atendente que o processo seja
                        iniciado. <br /><br />Pode realizar a solicita&ccedil;&atilde;o agora mesmo
                        <a href="https://api.whatsapp.com/send/?phone=556734205600" target="_blank">
                                    clicando Aqui
                                </a>   
                        ou pelo nosso </span
                    ><b>Chat</b><b>&nbsp;</b
                    ><span>do Portal do Cliente, ou se preferir, pode solicitar via e-mail </span
                    ><b>atendimento@saobento.com.br.</b>
                    </p>
                    `,
				},
				{
					question: 'Quais documentos preciso apresentar?',
					ans: `
                    <p class="c10">
                    <span
                        >A documenta&ccedil;&atilde;o &eacute; uma parte muito importante para a
                        realiza&ccedil;&atilde;o do aditivo de cess&atilde;o de direitos, pois ela &eacute; um
                        registro da regularidade do processo, na qual evita poss&iacute;veis problemas
                        futuros. <br /><br /></span
                    >
                    <b>É necessário que todos os documentos estejam legíveis e com os prazos de validade de acordo com a relação abaixo:</b>
                </p>
                <p class="c10 c15"><span class="c0"></span></p>
                <p class="c27 c28">
                    <span class="c7">Documentos do Cedente (quem est&aacute; vendendo):</span>
                </p>
                <ul class="c23 lst-kix_wg5ug26sb3f2-3 start">
                    <li class="c12 li-bullet-0"><span class="c0"><b>Documentos Pessoais:</b> CNH (não vencida) ou RG e CPF.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Estado Civil:</b> Se solteiro, enviar a Certidão de Nascimento <b>(atualizada em cartório com data de emissão não superior a 90 dias)</b>. Se em União Estável, enviar a Declaração de União Estável <b>(feita cartório)</b>. Se casado, enviar a Certidão de Casamento. Se divorciado ou viúvo, enviar a Certidão de Casamento <b>(atualizada em cartório com data de emissão não superior a 90 dias)</b>.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Informações:</b> E-mail <b>PESSOAL</b>, profissão, <b>DOIS</b> números de telefone/celular, chave PIX de conta <b>PESSOAL</b></span></li>
                </ul>
                <p class="c27"><span class="c0">&nbsp;</span></p>
                <p class="c27 c28">
                    <span class="c7"
                        >Documento do C&ocirc;njuge do Cedente (em caso de Cedente casado);</span
                    >
                </p>
                <ul class="c23 lst-kix_tqdpijr1my7c-0 start">
                    <li class="c12 li-bullet-0"><span class="c0"><b>Documentos Pessoais:</b> CNH (não vencida) ou RG e CPF.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Informações:</b> E-mail <b>PESSOAL</b>, profissão, <b>DOIS</b> números de telefone/celular, chave PIX de conta <b>PESSOAL</b></span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>OBS: Se houver alteração de nome, envie o documento pessoal atualizado com o nome e assinatura.</b> </span></li>
                </ul>
                <p class="c27"><span class="c0">&nbsp;</span></p>
                <p class="c27 c28">
                    <span class="c7">Documentos do Cession&aacute;rio (quem est&aacute; Comprando):</span>
                </p>
                <ul class="c23 lst-kix_xwwp6snkkemq-0 start">
                    <li class="c12 li-bullet-0"><span class="c0"><b>Documentos Pessoais:</b> CNH (não vencida) ou RG e CPF.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Estado Civil:</b> Se solteiro, enviar a Certidão de Nascimento. Se em União Estável, enviar a Declaração de União Estável <b>(feita em cartório)</b>. Se casado, enviar a Certidão de Casamento. Se divorciado ou viúvo, enviar a Certidão de Casamento <b>(atualizada em cartório com data de emissão não superior a 90 dias)</b>.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Comprovante de Residência:</b> Atualizado em até 60 dias.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Informações:</b> E-mail <b>PESSOAL</b>, profissão, <b>DOIS</b> números de telefone/celular.</span></li>
                </ul>
                <p class="c27"><span class="c0">&nbsp;</span></p>
                <p class="c27 c28">
                    <span class="c7"
                        >Documentos do C&ocirc;njuge do Cession&aacute;rio (em caso de Cession&aacute;rio
                        casado):</span
                    >
                </p>
                <ul class="c23 lst-kix_knz8a5ti5qav-0 start">
                    <li class="c12 li-bullet-0"><span class="c0"><b>Documentos Pessoais:</b> CNH (não vencida) ou RG e CPF.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Informações:</b> E-mail <b>PESSOAL</b>, profissão, <b>DOIS</b> números de telefone/celular, chave PIX de conta <b>PESSOAL</b></span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>OBS: Se houver alteração de nome, envie o documento pessoal atualizado com o nome e assinatura.</b> </span></li>
                </ul>
                <p class="c27"><span class="c0">&nbsp;</span></p>
                <p class="c27 c28">
                    <span class="c7"
                        >Caso o contrato esteja no nome de Pessoa Jur&iacute;dica, enviar tamb&eacute;m:</span
                    >
                </p>
                <ul class="c23 lst-kix_ypccf2ljwf23-0 start">
                    <li class="c12 li-bullet-0"><span class="c0"><b>Cartão CNPJ</b></span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Contrato Social</b></span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Última Alteração Contratual</b></span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Certidão Simplificada:</b> data de emissão não superior a 30 dias</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Comprovante de Endereço da empresa.</b></span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Informações:</b> E-mail <b>EMPRESARIAL</b> e <b>DOIS</b> números de telefone/celular.</span></li>
                </ul>
                <p class="c27"><span class="c0">&nbsp;</span></p>
                <p class="c27 c28">
                    <span class="c7"
                        >Representante(s) Legal(s):</span
                    >
                </p>
                <ul class="c23 lst-kix_ypccf2ljwf23-0 start">
                    <li class="c12 li-bullet-0"><span class="c0"><b>Documentos Pessoais:</b> CNH válida ou RG e CPF.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Estado Civil:</b> Se solteiro, enviar a Certidão de Nascimento. Se em União Estável, enviar a Declaração de União Estável <b>(feita em cartório)</b>. Se casado, enviar a Certidão de Casamento. Se divorciado ou viúvo, enviar a Certidão de Casamento <b>(atualizada em cartório com data de emissão não superior a 90 dias)</b>.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>Comprovante de Residência:</b> Atualizado em até 60 dias.</span></li>
                    <li class="c12 li-bullet-0"><span class="c0"><b>OBS: Se houver alteração de nome, envie o documento pessoal atualizado com o nome e assinatura.</b> </span></li>
                </ul>
                    `,
				},
				{
					question: 'Os documentos tem algum prazo de validade?',
					ans: `
                    <p>Sim, alguns documentos contam com um prazo para serem utilizados no processo. Segue a relação abaixo:</p>
                    <ul class="c23 lst-kix_sgotl4m0tx6v-0 start">
                    <li class="c45 c28 c24 li-bullet-0">
                        <span class="c0"
                            >CNH: O prazo de vencimento &eacute; o indicado no pr&oacute;prio documento;</span
                        >
                    </li>
                    <li class="c45 c28 c24 li-bullet-0">
                        <span class="c0"
                            >Certid&atilde;o de Nascimento ou Casamento atualizada: Prazo de 90 dias ap&oacute;s
                            a emiss&atilde;o;</span
                        >
                    </li>
                    <li class="c45 c28 c24 li-bullet-0">
                        <span class="c0"
                            >Certid&atilde;o Simplificada: Prazo de 30 dias ap&oacute;s a emiss&atilde;o;</span
                        >
                    </li>
                </ul>
                    <b>Lembrando que os prazos de validade definidos devem ser respeitados para uma segurança jurídica do processo do aditivo de cessão de direitos.</b>
                    `,
				},
				{
					question: 'A Cessão de Direitos tem algum custo?',
					ans: `
                    Sim, para a realização do aditivo de Cessão de Direitos geralmente há o custo de uma <b>taxa administrativa</b> equivalente a 2% do valor do contrato, mas essa porcentagem pode variar de acordo com o que está previsto em seu contrato. 
                    Para que possa ter acesso ao valor exato que será cobrado, consulte o seu contrato ou fale agora mesmo com um de nossos atendentes pelo <b>Chat</b> do Portal do Cliente.

                    `,
				},
				{
					question: 'Preciso pagar o IPTU para fazer a Cessão de Direitos?',
					ans: `
                    Sim, como vamos transferir a titularidade do seu lote para outra pessoa, é necessário que você esteja com o IPTU e demais débitos municipais, tais como: IPTU, taxa de coleta de resíduos sólidos, multa de construção, entre outros, quitados para que possamos realizar a Cessão de Direitos.
                    `,
				},
				{
					question: 'Qual o prazo para realizar a Cessão de Direitos?',
					ans: `
                    O aditivo de Cessão de Direitos tem um prazo máximo de 30 dias para conclusão. Todavia, caso toda a documentação seja entregue corretamente e todos os débitos estejam em dia, o processo poderá ser realizado dentro do prazo de 7 dias úteis. 
                    `,
				},
				{
					question: 'Preciso quitar as parcelas em atraso para realizar a Cessão de Direitos?',
					ans: `
                    Sim, enquanto o aditivo de Cessão de Direitos não for finalizado com as assinaturas de todos, é necessário o pagamento das parcelas em andamento.
                    Inclusive, enquanto o processo de Cessão de Direitos estiver em andamento é essencial que as parcelas do contrato continuem sendo pagas, para evitar que o processo seja cancelado.
                    `,
				},
				{
					question:
						'Preciso ir até o escritório para assinar o aditivo de Cessão de Direitos?',
					ans: `
                        <p>
                            Não, o aditivo de Cessão de Direitos é um processo <b>100% digital</b>. Ou seja, não há necessidade de se deslocar para o atendimento presencial ou para a realização das assinaturas.
                        </p>
                        <p>
                            A assinatura do aditivo de Cessão de Direitos é realizada por e-mail, de forma digital. Onde para o <b>cedente e respectivo cônjuge</b> é realizada uma validação da assinatura, via <b>pix</b>.
                        </p>     
                        <p>
                        Segue um vídeo explicativo sobre a assinatura do <b>cedente e cônjuge</b>:
                        <span class="c35 c18 c20"
                            ><a
                                class="c41"
                                target="_blank"
                                href="https://www.youtube.com/watch?v=XvQ8Fd74Qv4"
                                >Como assinar um documento com valida&ccedil;&atilde;o via pix.</a
                            ></span
                        >
  
                    </p>      
                    <p>
                    <iframe width="100%" class="d-md-none mx-auto" src="https://www.youtube.com/embed/XvQ8Fd74Qv4?si=zNz3LixfFXMJwqWBs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    
    
                    <iframe width="560" height="315" class="d-none d-md-block mx-auto" src="https://www.youtube.com/embed/XvQ8Fd74Qv4?si=zNz3LixfFXMJwqWBs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>   
                    </p>        
                    `,
				},
				{
					question: 'Posso fazer a Cessão de Direitos em lote quitado?',
					ans: `
                        Não, em caso de contrato quitado deve ser realizado o processo de Escritura definitiva.
                    `,
				},
				{
					question: 'Preciso fazer mais alguma coisa depois do aditivo de Cessão de Direitos?',
					ans: `
                    Não, após a assinatura do aditivo de Cessão de Direitos você pode deixar com a São Bento. Nós faremos contato com o novo titular e encaminharemos os acessos para o Portal do Cliente.                     `,
				},
				{
					question:
						'O que acontece se eu vender o lote e não realizar o aditivo de Cessão de Direitos?',
					ans: `
                        <p>
                            A venda particular do lote, sem a devida comunicação à São Bento e a realização do aditivo de Cessão de Direitos, pode acarretar em Notificação Extrajudicial, pois isso infringe as cláusulas contratuais. 
                        </p>
                        <p>
                            Portanto, se essa é a sua atual situação, regularize o quanto antes!
                        </p>
                    `,
				},
			],
		},
	},
}

mock.onGet('/faq/data').reply(config => {
	const { q = '' } = config.params
	const queryLowered = q.toLowerCase()

	const filteredData = {}

	Object.entries(data.faqData).forEach(entry => {
		const [categoryName, categoryObj] = entry
		// eslint-disable-next-line arrow-body-style
		const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
			return (
				qAndAObj.question.toLowerCase().includes(queryLowered) ||
				qAndAObj.ans.toLowerCase().includes(queryLowered)
			)
		})
		if (filteredQAndAOfCategory.length) {
			filteredData[categoryName] = {
				...categoryObj,
				qandA: filteredQAndAOfCategory,
			}
		}
	})

	return [200, filteredData]
})

mock.onAny().passThrough()
